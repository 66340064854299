import React, { useState, useEffect } from "react";

import "react-datepicker/dist/react-datepicker.css";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { setMinutes, setHours, setSeconds } from "date-fns";
import { Button, Input } from "@material-ui/core";
import "./App.css";
import axios from "axios";
import MinimizeIcon from "@material-ui/icons/Minimize";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";

function App() {
  //const [selectedDays, setSelectedDays] = useState([]);
  const CurrentDate = new Date();
  const [openTime, setOpentime] = useState(setHours(setMinutes(setSeconds(CurrentDate, 0), 0), 9));
  const [closeTime, setCloseime] = useState(setHours(setMinutes(setSeconds(CurrentDate, 0), 0), 17));
  const [email, setEmail] = useState("");
  const [appName, setappName] = useState("");
  const [appDetails, setappDetails] = useState("");
  //const [timeZone, setTimeZone] = useState("");
  const [accountID, setAccountID] = useState("");
  const [timeZonestate, settimeZonestateState] = React.useState("");
  let verifyFlag = true;
  //const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let timeZone = moment().format("Z");
  const convertTimeZone = (timeZone) => {
    if (timeZone[0] == "+") {
      return "plus" + timeZone.substring(1, timeZone.length);
    } else {
      return "minus" + timeZone.substring(1, timeZone.length);
    }
  };

  const [Days, setDays] = useState({
    Monday: 1,
    Tuesday: 1,
    Wednesday: 1,
    Thursday: 1,
    Friday: 1,
    Saturday: 0,
    Sunday: 0,
  });
  const [duration, setDuration] = useState({
    15: 0,
    30: 0,
    60: 1,
  });
  const [appointmentType, setAppointmentType] = useState({
    online: 0,
    offline: 1,
  });

  useEffect(() => {
    document.querySelector(".app__Email").setAttribute("style", "display:flex");
    settimeZonestateState((prevState) => timeZone);
    let verifyID1;
    try {
      verifyID1 = decodeURIComponent(new URLSearchParams(window.location.search).get("key"));
    } catch {
      verifyID1 = null;
    }
    let accountID1 = decodeURIComponent(new URLSearchParams(window.location.search).get("id"));
    // check session for verification
    console.log("verification process", verifyID1, accountID1);
    // if (verifyID1){
    if (verifyID1 == "") {
      console.log("user not verified");
      setTimeout(() => {
        window.location.replace("https://node0.linkedphone.co/api/v1/failedVerification");
      }, 1000);
      verifyFlag = false;
    }
    axios
      .post("https://node0.linkedphone.co/api/v1/dialogflow/verifyID", {
        accountID: accountID1,
        verifyID: verifyID1,
      })
      .then((data) => {
        console.log(data.data);
        if (data.data.success == false) {
          console.log("user not verified");
          setTimeout(() => {
            window.location.replace("https://node0.linkedphone.co/api/v1/failedVerification");
          }, 1000);

          verifyFlag = false;
        }
        console.log("user verified");

        setTimeout(() => {
          document.querySelector("#preloader").classList.add("hide");
          document.querySelector(".app__Email").setAttribute("style", "display:none");
          document.querySelector(".app__AppointmentName").setAttribute("style", "display:flex");
          //document.querySelector(".app__DayPicker").setAttribute("style", "display:flex");
        }, 2000);

        console.log("App screen loaded");
        //console.log(new URLSearchParams(window.location.search).get('id'));
        setAccountID(decodeURIComponent(new URLSearchParams(window.location.search).get("id")));
        const emailIDQuery = new URLSearchParams(window.location.search).get("email");
        //const timeZoneQuery = new URLSearchParams(window.location.search).get("timeZone");
        if (emailIDQuery) {
          setEmail(decodeURIComponent(emailIDQuery));
        } else {
          setEmail("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [0]);

  useEffect(() => {
    if (appName.length == 0) {
      document.querySelector("#app__AppointmentNameNext").classList.add("hide");
    } else {
      document.querySelector("#app__AppointmentNameNext").classList.remove("hide");
    }
  }, [appName]);

  const keydownTimeInput = (e) => {
    e.preventDefault();
    return false;
  };

  const verifyEmail = (email) => {
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (re.test(email)) {
      return true;
    } else {
      return false;
    }
  };

  const appNameNext = () => {
    if (appName.length > 0) {
      console.log("appName format verified");
      document.querySelector(".app__AppointmentName").setAttribute("style", "display:none");
      document.querySelector(".app__DayPicker").setAttribute("style", "display:flex");
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      document.querySelector("#email__error").classList.remove("hide");
    }
  };

  const timeNext = () => {
    console.log("Opening and closing times: ", moment(openTime).format("HH:mm:ss"), moment(closeTime).format("HH:mm:ss"));
    let selectedDays = [];
    for (let key in Days) {
      if (Days[key] == 1) {
        selectedDays.push(key);
      }
    }
    console.log("Selected Days: ", selectedDays);
    let appointmentSlected = "";
    for (let key in appointmentType) {
      if (appointmentType[key] == 1) {
        appointmentSlected = key;
      }
    }
    console.log("Appointment Type: ", appointmentSlected);
    console.log("Appointment Duration: ", duration);

    if (appDetails.length == 0) {
      alert("Appointment Details cannot be empty");
      return;
    }
    let uploadData = {
      email: email,
      businessHours: {
        businessDays: selectedDays,
        businessTimings: [moment(openTime).format("HH:mm:ss"), moment(closeTime).format("HH:mm:ss")],
      },
      accountID: accountID,
      appointmentType: appointmentSlected,
      appointmentDuration: duration,
      timeZone: convertTimeZone(timeZonestate),
      appointmentName: appName,
      appointmentDetails: appDetails,
    };
    console.log("upload Data", uploadData);
    axios
      .post("https://node0.linkedphone.co/api/v1/preRegistration", {
        email: email,
      })
      .then((data) => {
        if (data.data.success == true) {
          axios
            .post("https://node0.linkedphone.co/api/v1/registration", {
              //   email: email,
              //   businessHours: {
              //     businessDays: selectedDays,
              //     businessTimings: [
              //       moment(openTime).format("HH:mm:ss"),
              //       moment(closeTime).format("HH:mm:ss"),
              //     ],
              //   },
              //   accountID: accountID,
              //   //timeZone: timeZone,
              uploadData,
            })
            .then((regResult) => {
              if (regResult.data.success == false) {
                alert(regResult.message);
              }
              if (regResult.data.url) {
                console.log("successfull getting registration url", regResult.data);
                document.querySelector(".app__DayPicker").setAttribute("style", "display:none");
                // document
                //   .querySelector(".app__TimePicker")
                //   .classList.add("hide");
                //   document
                //   .querySelector("#app__TimePicker__BackButton")
                //   .classList.add("hide");
                document.querySelector(".app__googleMessage").classList.remove("hide");
                setTimeout(() => {
                  window.location.href = regResult.data.url;
                }, 2000);
              }
            })
            .catch((regError) => {
              console.log(regError);
            });
        } else {
          console.log(data);
          alert(data.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setDaysHandler = (e) => {
    if (Days[e.target.value] == 0) {
      // Days[e.target.value] = 1;
      // document.querySelector(`#${e.target.value}`).classList.add("hoverColor");
      setDays({
        ...Days,
        [e.target.value]: 1,
      });
      document.querySelector(`#${e.target.value}`).style.backgroundColor = "#55a9da";
      document.querySelector(`#${e.target.value}`).style.color = "white";
    } else {
      // Days[e.target.value] = 0;
      // document.querySelector(`#${e.target.value}`).classList.remove("hoverColor");
      setDays({
        ...Days,
        [e.target.value]: 0,
      });
      // document
      //   .querySelector(`#${e.target.value}`)
      //   .classList.remove("hoverColor");
      document.querySelector(`#${e.target.value}`).style.backgroundColor = "#EEEFEF";
      document.querySelector(`#${e.target.value}`).style.color = "black";
    }
  };

  const setDurationHandler = (e) => {
    if (duration[e.target.value] == 0) {
      setDuration({
        ...duration,
        [e.target.value]: 1,
      });
      document.querySelector(`#min${e.target.value}`).style.backgroundColor = "#55a9da";
      document.querySelector(`#min${e.target.value}`).style.color = "white";
    } else {
      setDuration({
        ...duration,
        [e.target.value]: 0,
      });
      document.querySelector(`#min${e.target.value}`).style.backgroundColor = "#EEEFEF";
      document.querySelector(`#min${e.target.value}`).style.color = "black";
    }
  };

  const setAppointmentHandler = (e) => {
    if (e.target.value == "online") {
      setAppointmentType({
        ...appointmentType,
        [e.target.value]: 1,
        ["offline"]: 0,
      });
      document.querySelector(`#${e.target.value}Appointment`).style.backgroundColor = "#55a9da";
      document.querySelector(`#${e.target.value}Appointment`).style.color = "white";
      document.querySelector(`#offlineAppointment`).style.backgroundColor = "#EEEFEF";
      document.querySelector(`#offlineAppointment`).style.color = "black";
    } else {
      setAppointmentType({
        ...appointmentType,
        [e.target.value]: 1,
        ["online"]: 0,
      });
      document.querySelector(`#${e.target.value}Appointment`).style.backgroundColor = "#55a9da";
      document.querySelector(`#${e.target.value}Appointment`).style.color = "white";
      document.querySelector(`#onlineAppointment`).style.backgroundColor = "#EEEFEF";
      document.querySelector(`#onlineAppointment`).style.color = "black";
    }
  };

  const dayPickerBack = () => {
    console.log("going back to email");
    //setSelectedDays([]);
    document.querySelector(".app__DayPicker").setAttribute("style", "display:none");
    document.querySelector(".app__AppointmentName").setAttribute("style", "display:flex");
  };

  const timeBack = () => {
    console.log("going back to clanedar");
    //setSelectedDays([]);
    document.querySelector(".app__TimePicker").classList.add("hide");
    document.querySelector(".app__DayPicker").setAttribute("style", "display:flex");
  };

  useEffect(() => {
    console.log(Days);
    let flag = true;
    let durationFlag = true;
    console.log("handler for day picker next button");
    for (let key in Days) {
      if (Days[key] == 1) {
        flag = false;
      }
    }
    for (let key in duration) {
      if (duration[key] == 1) {
        durationFlag = false;
      }
    }
    if (!flag) {
      document.querySelector("#app__DayPicker__Nextbutton").classList.remove("hide");
    } else {
      document.querySelector("#app__DayPicker__Nextbutton").classList.add("hide");
    }
    if (durationFlag) {
      document.querySelector("#app__DayPicker__Nextbutton").classList.add("hide");
    }
  }, [Days]);

  useEffect(() => {
    console.log("duration button clicked");
    console.log(duration);
    let flag = true;
    let DaysFlag = true;
    for (let key in duration) {
      if (duration[key] == 1) {
        flag = false;
      }
    }
    for (let key in Days) {
      if (Days[key] == 1) {
        DaysFlag = false;
      }
    }
    if (!flag) {
      document.querySelector("#app__DayPicker__Nextbutton").classList.remove("hide");
    } else {
      document.querySelector("#app__DayPicker__Nextbutton").classList.add("hide");
    }
    if (DaysFlag) {
      document.querySelector("#app__DayPicker__Nextbutton").classList.add("hide");
    }
  }, [duration]);

  useEffect(() => {
    console.log("appointmentType button clicked");
    console.log(appointmentType);
  }, [appointmentType]);

  const CustomInputButtonTime = ({ value, onClick }) => (
    <button className='app__TimePicker__Container__timeInput' style={{ width: "100px" }} onClick={onClick}>
      {value}
    </button>
  );

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();
  const handleChange = (event) => {
    settimeZonestateState((prevState) => event.target.value);
  };

  useEffect(() => {
    console.log(timeZonestate);
  }, [timeZonestate]);

  return (
    <div className='app'>
      {/* Email Div */}
      <div className='app__Email'>
        <img className='calendar__Icon' src='./calendar-512.png' alt='calendar icon' />
        <p>Welcome to Linkedphone AI services</p>
        <img id='preloader' src='loader_gif.svg' alt='loader gif'></img>
      </div>
      <div className='app__AppointmentName'>
        <div className='app__AppointmentNameContainer'>
          <div className='app__appName_Container__Top'>
            <div className='app__appName_Container__TopText'>
              <h3>Set your Appointment Name</h3>
            </div>
            <img src='./clock-512.png' alt='clock image' width='150px' height='150px'></img>
          </div>
          <div className='app__appName_Container__Bottom'>
            <p>Please enter appointment name to get started.</p>
            <input
              className='appName__input'
              placeholder='Ex. "Regular Haircut", "Counseling".'
              value={appName}
              onChange={(e) => {
                setappName(e.target.value);
                document.querySelector("#email__error").classList.add("hide");
              }}
            ></input>
            <p id='email__error' className='error__Message hide'>
              Please enter valid Name
            </p>
            <button id='app__AppointmentNameNext' className='next__button' onClick={appNameNext}>
              Next
            </button>
          </div>
        </div>
        <div className='progress__Bar'>
          <div className='progress__Bar__rect' id='progress__Bar_1' style={{ backgroundColor: "#0082ce" }}></div>
          <div className='progress__Bar__rect' id='progress__Bar_2'></div>
          <div className='progress__Bar__rect' id='progress__Bar_3'></div>
        </div>
      </div>
      {/* Day Picker Div */}
      <div className='app__DayPicker'>
        <div className='app__DayPicker__Container'>
          <div className='app__DayPicker_Container__Top'>
            <div className='app__DayPicker_Container__TopText'>
              <h3>Set your availability</h3>
              <p>Configure your appointment details and availability</p>
            </div>
            <img src='./clock-512.png' alt='clock image' width='150px' height='150px'></img>
          </div>
          <div className='app__DayPicker_Bottom'>
            <p>Appointment Details and Instructions</p>
            <textarea
              className='appDetails__input'
              placeholder='( Will be included in appointment email to client)'
              value={appDetails}
              onChange={(e) => {
                setappDetails(e.target.value);
                document.querySelector("#appDetail__error").classList.add("hide");
              }}
            ></textarea>
            <p id='appDetail__error' className='error__Message hide'>
              This field cannot be empty
            </p>
            <p>TimeZone</p>
            <Select
              id='timezone'
              name='timezone'
              name='timezone'
              id='timezone'
              native
              value={timeZonestate}
              onChange={handleChange}
              inputProps={{
                id: "age-native-simple",
              }}
            >
              <option value='-12:00'>(UTC-12:00) International Date Line West</option>
              <option value='-11:00'>(UTC-11:00) Midway Island, Samoa</option>
              <option value='-09:00'>(UTC-09:00) Hawaii</option>
              <option value='-08:00'>(UTC-08:00) Alaska</option>
              <option value='-07:00'>(UTC-07:00) Pacific Time</option>
              <option value='-06:00'>(UTC-06:00) Mountain Time</option>
              <option value='-05:00'>(UTC-05:00) Central Time</option>
              <option value='-04:00'>(UTC-04:00) Eastern Time</option>
              <option value='-03:00'>(UTC-03:00) Atlantic Time</option>
              <option value='-03:30'>(UTC-03:30) Newfoundland</option>
              <option value='-02:00'>(UTC-02:00) Mid-Atlantic</option>
              <option value='-01:00'>(UTC-01:00) Cape Verde Is</option>
              <option value='+00:00'>(UTC+00:00) Greenwich Mean Time</option>
              <option value='+01:00'>(UTC+01:00) Amsterdam, Berlin, Bern, Rome</option>
              <option value='+02:00'>(UTC+02:00) Helsinki, Kyiv, Riga, Sofia</option>
              <option value='+03:00'>(UTC+03:00) Moscow, St. Petersburg, Volgograd</option>
              <option value='+03:30'>(UTC+03:30) Tehran</option>
              <option value='+04:00'>(UTC+04:00) Abu Dhabi, Muscat</option>
              <option value='+04:30'>(UTC+04:30) Kabul</option>
              <option value='+05:00'>(UTC+05:00) Yekaterinburg</option>
              <option value='+05:30'>(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
              <option value='+05:45'>(UTC+05:45) Kathmandu</option>
              <option value='+06:00'>(UTC+06:00) Almaty, Novosibirsk</option>
              <option value='+06:30'>(UTC+06:30) Yangon</option>
              <option value='+07:00'>(UTC+07:00) Bangkok, Hanoi, Jakarta</option>
              <option value='+08:00'>(UTC+08:00) Beijing, Chongqing, Hong Kong</option>
              <option value='+09:00'>(UTC+09:00) Osaka, Sapporo, Tokyo</option>
              <option value='+09:30'>(UTC+09:30) Adelaide</option>
              <option value='+10:00'>(UTC+10:00) Canberra, Melbourne, Sydney</option>
              <option value='+11:00'>(UTC+11:00) Magadan, Solomon Is, New Caledonia</option>
              <option value='+12:00'>(UTC+12:00) Auckland, Wellington</option>
              <option value='+13:00'>(UTC+13:00) Nukualofa</option>
            </Select>
            {/* </FormControl> */}
            <p>Availalbe Hours</p>
            <div className='app__TimePicker__Container'>
              <DatePicker
                // className="app__TimePicker__Container__timeInput"
                selected={openTime}
                onChange={(date) => {
                  setOpentime(date);
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption='Time'
                dateFormat='h:mm aa'
                onKeyDown={keydownTimeInput}
                dropdownMode='scroll'
                customInput={<CustomInputButtonTime />}
              />
              <MinimizeIcon style={{ marginRight: "10px" }} />
              <DatePicker
                // className="app__TimePicker__Container__timeInput"
                selected={closeTime}
                onChange={(date) => {
                  setCloseime(date);
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                minTime={openTime}
                maxTime={setHours(setMinutes(openTime, 45), 23)}
                timeCaption='Time'
                dateFormat='h:mm aa'
                onKeyDown={keydownTimeInput}
                dropdownMode='scroll'
                customInput={<CustomInputButtonTime />}
              />
            </div>
            <p>Appointment Type</p>
            <div className='app__DayPicker__days__appointment'>
              <button id='onlineAppointment' className='app__DayPicker__days__button__appointmen' variant='contained' value='online' onClick={setAppointmentHandler}>
                Online Appointment
              </button>
              <button
                id='offlineAppointment'
                className='app__DayPicker__days__button__appointmen'
                variant='contained'
                value='offline'
                onClick={setAppointmentHandler}
                style={{
                  backgroundColor: "#55a9da",
                  color: "white",
                }}
              >
                In-person Appointment
              </button>
            </div>
            <p>Meeting Duration ( choose one or more)</p>
            <div className='app__DayPicker__days'>
              <button
                id='min60'
                className='app__DayPicker__days__button'
                variant='contained'
                value='60'
                onClick={setDurationHandler}
                style={{
                  backgroundColor: "#55a9da",
                  color: "white",
                }}
              >
                60 Minute Meeting
              </button>
              <button id='min30' className='app__DayPicker__days__button' variant='contained' value='30' onClick={setDurationHandler}>
                30 Minute Meeting
              </button>
              <button id='min15' className='app__DayPicker__days__button' variant='contained' value='15' onClick={setDurationHandler}>
                15 Minute Meeting
              </button>
            </div>
            <p>Availalbe Days</p>
            <div className='app__DayPicker__days'>
              <button
                id='Monday'
                className='app__DayPicker__days__button'
                variant='contained'
                value='Monday'
                onClick={setDaysHandler}
                style={{
                  backgroundColor: "#55a9da",
                  color: "white",
                }}
              >
                Monday
              </button>
              <button
                id='Tuesday'
                className='app__DayPicker__days__button'
                variant='contained'
                value='Tuesday'
                onClick={setDaysHandler}
                style={{
                  backgroundColor: "#55a9da",
                  color: "white",
                }}
              >
                Tuesday
              </button>
              <button
                id='Wednesday'
                className='app__DayPicker__days__button'
                variant='contained'
                value='Wednesday'
                onClick={setDaysHandler}
                style={{
                  backgroundColor: "#55a9da",
                  color: "white",
                }}
              >
                Wednesday
              </button>
              <button
                id='Thursday'
                className='app__DayPicker__days__button'
                variant='contained'
                value='Thursday'
                onClick={setDaysHandler}
                style={{
                  backgroundColor: "#55a9da",
                  color: "white",
                }}
              >
                Thursday
              </button>
              <button
                id='Friday'
                className='app__DayPicker__days__button'
                variant='contained'
                value='Friday'
                onClick={setDaysHandler}
                style={{
                  backgroundColor: "#55a9da",
                  color: "white",
                }}
              >
                Friday
              </button>
              <button id='Saturday' className='app__DayPicker__days__button' variant='contained' value='Saturday' onClick={setDaysHandler}>
                Saturday
              </button>
              <button id='Sunday' className='app__DayPicker__days__button' variant='contained' value='Sunday' onClick={setDaysHandler}>
                Sunday
              </button>
            </div>

            <div className='app__buttons'>
              <button id='app__DayPicker__Backbutton' className='next__button' onClick={dayPickerBack}>
                Back
              </button>
              <button id='app__DayPicker__Nextbutton' className='next__button hide' onClick={timeNext}>
                Next
              </button>
            </div>
          </div>
        </div>

        <div className='progress__Bar'>
          <div className='progress__Bar__rect' id='progress__Bar_1'></div>
          <div className='progress__Bar__rect' id='progress__Bar_2' style={{ backgroundColor: "#0082ce" }}></div>
          <div className='progress__Bar__rect' id='progress__Bar_3'></div>
        </div>
      </div>
      {/* google redirect message div */}
      <div className='app__googleMessage hide'>
        <p>You are almost done! Please give permissions in the next screen</p>
      </div>
    </div>
  );
}

export default App;
